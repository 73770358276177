<script>
import InvalidScanDialog from "./InvalidScanDialog";
import { mountsBarcodeHandlerWhenActive } from "@/mixins/barcode";
import { COUPONS_LIST_VIEW } from "./graphql/queries";
import { mapMutations } from "vuex";

export default {
  name: "BouncebackScan",
  components: { InvalidScanDialog },
  mixins: [mountsBarcodeHandlerWhenActive],
  props: {
    storeId: { type: String, default: "" },
  },
  data() {
    return {
      couponsLoading: false,
      searchNumber: undefined,
      invalidScanDialogOpened: false,
    };
  },
  computed: {
    queryVariables() {
      return {
        storeId: this.storeId,
      };
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    handleBarcode({ detail: { data } }) {
      const searchNumber = data;

      if (this.storeId && searchNumber) {
        this.$apollo
          .query({
            query: COUPONS_LIST_VIEW,
            loadingKey: "couponsLoading",
            variables: {
              ...this.queryVariables,
              page: 1,
              descending: true,
              first: null,
              last: null,
              after: null,
              before: null,
              startCursor: null,
              endCursor: null,
              sortBy: "transactionId",
              filter: searchNumber,
            },
          })
          .then((response) => {
            if (response.data.coupons.edges.length !== 1) {
              this.invalidScanDialogOpened = true;
            } else {
              const routeToNavigateTo = {
                name: "bounceback_show",
                params: {
                  id: response.data.coupons.edges[0].node.id,
                },
                preserveQuery: true,
              };
              if (this.$route.name === "bounceback_show") {
                // If we are already displaying a bounceback, just display a new one,
                // but do not add an item to browser history.
                this.$router.replace(routeToNavigateTo);
              } else {
                this.$router.push(routeToNavigateTo);
              }
            }
          })
          .catch((error) => {
            this.showSnackbar({
              text: this.$t(
                "bounceback.app.bouncebackScan.retrieveCouponError",
                { error }
              ),
            });
          });
      }
    },
  },
};
</script>

<template>
  <InvalidScanDialog v-model="invalidScanDialogOpened" />
</template>
